import {
  Grid,
  Flex,
  Loader,
  useAuthenticator,
  View,
} from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';
import { AdminPage } from '../Admin/AdminPage';
import { NavBar } from './NavBar';
import { SearchPage } from './SearchPage';

export function Home() {
  const sessionKey = 'CURRENT_PAGE';
  const initialPage = sessionStorage.getItem(sessionKey) || 'search';
  const [page, setPage] = useState(initialPage);
  const [loading, setLoading] = useState(false);
  const { signOut, user } = useAuthenticator();

  useEffect(() => {
    // console.log('useEffect ->  page', page);
    sessionStorage.setItem(sessionKey, page);
    return () => {
      sessionStorage.removeItem(sessionKey);
    };
  }, [page]);

  return (
    <Grid
      maxWidth="100vw"
      templateColumns="100vw"
      templateRows="150px 10fr">
      <View columnSpan="2">
        <NavBar signOut={signOut} user={user} setPage={setPage} />
        <Flex height="30px">
          <Loader
            variation="linear"
            size="small"
            display={loading ? 'inline-block' : 'none'}
          />
        </Flex>
      </View>
      <View columnSpan="2">
        {page === 'search' && (
          <SearchPage loading={loading} setLoading={setLoading} />
        )}
        {page === 'admin' && (
          <AdminPage loading={loading} setLoading={setLoading} />
        )}
      </View>
    </Grid>
  );
}
